/* eslint-disable */

import React from 'react';
import './TikTokOffer.css'
import Cta from '../CTA/CTA'
import Lottie from 'react-lottie'
import { StaticImage } from 'gatsby-plugin-image';


const offerImage = '../../images/MImgs/1.png'


export default function TikTokOffer ({handleCTA, lottieOptions, handleScrollDownClick, variant}){
   

    return(

        <div className = 'tiktok-offer-main'>
            <div className = 'tiktok-offer-title'>
                {variant === "IG"? "Instagram Exclusive": variant ==='YT'? "YouTube Exclusive!": "TikTok Exclusive!"}
            </div>
            <StaticImage objectFit = 'contain' className = 'tiktok-offer-image' src= {offerImage} />
            <div className = 'tiktok-offer-description'>
                15% off your first box of Bender
            </div>
            <Cta variant = 'tiktok offer' handleCTA = {handleCTA}/>
            <div  onClick  ={handleScrollDownClick}>
                <Lottie options={lottieOptions}  style= {{transform:'rotate(180deg)'}} height={75} width={75} />
            </div>
        </div>

    )

}